import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useMemo } from 'react';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const createApolloClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      setContext(async (_, { headers }) => {
        // get the authentication token from local storage if it exists
        if (typeof window !== 'undefined') {
          const token = localStorage.getItem('token') || '';
          // return the headers to the context so httpLink can read them
          if (token !== '') {
            return {
              headers: {
                ...headers,
                Authorization: `Bearer ${token}`,
              },
            };
          }
        }
        return {
          headers: {
            ...headers,
            // 'X-Hasura-Admin-Secret': 'castl3-p3ak-secret-hinsxd__secret?',
            // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsiYWRtaW4iLCJ1c2VyIiwiYW5vbnltb3VzIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6ImFub255bW91cyJ9LCJpYXQiOjE1OTQ1MzQzMDZ9.X00c2xiHl50MJD8Nz7Ns7zrN94TlUaQLSi4hqWC0ZZk`,
          },
        };
      }),
      new HttpLink({
        uri: process.env.NEXT_PUBLIC_HASURA_ENDPOINT,
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        vehicle: {
          keyFields: ['regno'],
        },
        Query: {
          fields: {
            transaction: {
              keyArgs: ['order_by', 'where'],
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            vehicle: {
              keyArgs: ['order_by', 'where'],
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            client: {
              keyArgs: ['order_by', 'where'],
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
            introducer: {
              keyArgs: ['order_by', 'where'],
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });
};
export function initializeApollo(initialState: any = null) {
  apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  // if (typeof window === 'undefined') return apolloClient;
  // Create the Apollo Client once in the client
  // if (!apolloClient) apolloClient = apolloClient;

  return apolloClient;
}

export function useApollo(initialState?: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
